// ContractorInfo.tsx

import React, { useState } from "react";
import { Link, useRouteLoaderData } from "react-router-dom";
import { FormatLink } from "../utils/formaters";
import { copyToClipBoard } from "../utils/clipboard";
import ApplicationInterviewForm from "./ApplicationInterviewForm";

// Import necessary icons
import text_bubble_icon from "../img/icons/message-text-square-01.png";
import coins_stacked_icon from "../img/icons/coins-stacked-02.png";
import linkedin_icon from "../img/icons/linkedin-icon.png";
import pie_chart_icon from "../img/icons/pie-chart-purple.png";
import receipt_icon from "../img/icons/receipt-purple.png";
import sliders_icon from "../img/icons/sliders-03.png";
import coins_hand_icon from "../img/icons/coins-hand-green.png";
import web_icon from "../img/icons/web-icon.png";
import hot_candidate_icon from "../img/icons/hot_candidate.png";
import resume_icon from "../img/icons/resume-icon.png";
import clock_icon from "../img/icons/clock-icon.png";
import pencilIcon from "../img/icons/pencil.png";
import juniorBadge from "../img/icons/junior-badge.png";
import midBadge from "../img/icons/mid-badge.png";
import seniorBadge from "../img/icons/senior-badge.png";
import leadBadge from "../img/icons/lead-badge.png";
import copyIcon from "../img/icons/copy.png";

// Import the default contractor icon
const contractor_icon = require("../img/indivIcon.png"); // Ensure this path is correct

interface ContractorInfoProps {
    contractor: any;
    application?: any;
    editLink: string;
    resource_request_source?: any;
}

const ContractorInfo: React.FC<ContractorInfoProps> = ({ contractor, application, editLink, resource_request_source }) => {

    const [imageError, setImageError] = useState(false); // State to track image load errors

    const GetOneTimeBenefitsCost = () => {
        if (!application || !application.proposal || !resource_request_source) return 0;
        let total = 0;
        resource_request_source.benefits.forEach((benefit: any) => {
            application?.proposal.benefits.forEach((subBenefit: any) => {
                if (benefit.id === subBenefit.id && benefit.one_time_only) {
                    total += benefit.cost;
                }
            });
        });
        return total;
    };

    const GetMonthlyBenefitsCost = () => {
        if (!application?.proposal || !resource_request_source) return 0;

        let total = 0;
        resource_request_source.benefits.forEach((benefit: any) => {
            application.proposal.benefits.forEach((subBenefit: any) => {
                if (subBenefit.id === benefit.id && !benefit.one_time_only) {
                    total += benefit.cost;
                }
            });
        });
        return total;
    };

    const GetPlatformFee = () => {
        if (!application?.proposal) return 0;
        const benefits = GetOneTimeBenefitsCost() + GetMonthlyBenefitsCost() * 12;
        const total = benefits + application.proposal.yearly_salary;
        const fee = total * 0.2; // 20%
        return fee;
    };

    const GetGatewayFee = () => {
        if (!application?.proposal) return 0;
        const benefits = GetOneTimeBenefitsCost() + GetMonthlyBenefitsCost() * 12;
        const comuna_fee = GetPlatformFee();
        const total = benefits + application.proposal.yearly_salary + comuna_fee;
        const gateway_fee = total * 0.029 + 0.3;
        return gateway_fee;
    };

    const GetTotalContractCost = () => {
        if (!application?.proposal) return 0;
        const benefits = GetOneTimeBenefitsCost() + GetMonthlyBenefitsCost() * 12;
        const comuna_fee = GetPlatformFee();
        const total = benefits + application.proposal.yearly_salary + comuna_fee;
        return total;
    };

    const StateBadge = () => {
        if (!application) return null;

        if (application.proposal?.state === 1) {
            return <div className="green-badge big">Hired</div>;
        }

        if (
            (application.proposal && (application.proposal.state === 2 || application.proposal.state === 3)) ||
            (!application.proposal && application.state === 6)
        ) {
            return <div className="red-badge big">Rejected</div>;
        }

        switch (application.state) {
            case 0:
                return <div className="blue-badge big mr-2">Screening Candidate</div>;
            case 1:
                return <div className="orange-badge big mr-2">Cultural Fit Interview</div>;
            case 2:
                return <div className="yellow-badge big mr-2">Technical Interview</div>;
            case 3:
                return <div className="purple-badge big mr-2">Partner Interview</div>;
            case 4:
            case 5:
                return <div className="blue-badge big mr-2">Offer Sent</div>;
            default:
                return null;
        }
    };

    const InterviewBadge = () => {
        if (!application) return null;

        const interviewRequested = application.interview_available_dates || application.interview_calendar_link;
        const interviewScheduled = application.interview_call_link;

        if (interviewScheduled) {
            return (
                <div className="blue-badge big flex flex-row mr-2 items-center">
                    <img
                        style={{ width: 16, height: 16, marginRight: 4 }}
                        src={clock_icon}
                        alt="Clock Icon"
                    />
                    Interview scheduled
                </div>
            );
        }

        if (interviewRequested) {
            return (
                <div className="blue-badge big flex flex-row mr-2 items-center">
                    <img
                        style={{ width: 16, height: 16, marginRight: 4 }}
                        src={clock_icon}
                        alt="Clock Icon"
                    />
                    Interview requested
                </div>
            );
        }

        return null;
    };

    const Skills = () => (
        <div className="flex flex-row flex-wrap mt-2">
            {contractor.skills?.map((skill: any, index: number) => (
                <span key={index} className="skill-badge tooltip mb-2 mr-2">
                    {skill.name ? skill.name: skill}
                </span>
            ))}
        </div>
    );

    // Helper function to capitalize the first letter
    const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

    return (
        <div>
            {application && (
                <div className="text-left">
                    <ApplicationInterviewForm application={application} />
                </div>
            )}

            {/* Contractor Information */}
            <div className="flex flex-row justify-between items-center bg-purple-light mb-3 rounded-lg p-6 mt-8">
                <div className="flex flex-row items-center">
                    {/* Profile Picture */}
                    <div className="relative">
                        {contractor.profile_picture && !imageError ? (
                            <img
                                src={contractor.profile_picture}
                                alt={`${capitalize(contractor.first_name)} ${capitalize(contractor.last_name)}`}
                                style={{ height: 128, width: 128, borderRadius: "50%" }}
                                onError={() => setImageError(true)} // Handle image load error
                            />
                        ) : (
                            <div
                                className="bg-comuna-purple flex justify-center items-center font-bold text-white text-4xl"
                                style={{
                                    height: 128,
                                    width: 128,
                                    borderRadius: "50%",
                                    lineHeight: 0,
                                }}
                            >
                                {`${capitalize(contractor.first_name.charAt(0))}${capitalize(contractor.last_name.charAt(0))}`}
                            </div>
                        )}
                    </div>

                    {/* Name, Title, and Badges */}
                    <div className="flex flex-col ml-4 items-start w-fit">
                        <h3 style={{ height: 31 }} className="font-semibold black-text">
                            {`${capitalize(contractor.first_name)} ${capitalize(contractor.last_name)}`}
                        </h3>
                        <span className="mb-2">
                            {contractor.title} - {contractor.country_location}
                        </span>
                        <div className="flex flex-row items-center w-fit">
                            <StateBadge />
                            <InterviewBadge />
                            {contractor.top_candidate && (
                                <div className="flex flex-row items-center yellow-badge big">
                                    <img
                                        style={{ width: 16, height: 16, marginRight: 4 }}
                                        src={hot_candidate_icon}
                                        alt="Hot Candidate Icon"
                                    />
                                    Hot Candidate
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Quick Actions Buttons */}
                <div className="flex flex-row items-start justify-end flex-1">
                    {contractor.website && contractor.website !== "null" && (
                        <a
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="comuna-circle-button"
                            href={FormatLink(contractor.website)}
                        >
                            <img src={web_icon} alt="Website" />
                        </a>
                    )}

                    {contractor.linkedin && contractor.linkedin !== "null" && (
                        <a
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="comuna-circle-button"
                            href={FormatLink(contractor.linkedin)}
                        >
                            <img src={linkedin_icon} alt="LinkedIn" />
                        </a>
                    )}

                    {contractor.cv && contractor.cv !== "null" && (
                        <a
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="comuna-circle-button"
                            href={FormatLink(contractor.cv)}
                        >
                            <img src={resume_icon} alt="Resume" />
                        </a>
                    )}

                    <Link className="self-center ml-3" to={editLink}>
                        <button className="comuna-secondary-button items-center w-fit">
                            <img src={pencilIcon} style={{ width: 16, height: 16 }} alt="Edit Icon" />
                            <label className="ml-2">Edit {application? "application": "profile"}</label>
                        </button>
                    </Link>
                </div>
            </div>

            {/* Experience and Language Fluency */}
            <div className="flex flex-row mb-3 w-full">
                {contractor.seniority === 1 && (
                    <div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
                        <img src={juniorBadge} alt="Junior Badge" style={{ height: 24, marginRight: 12 }} />
                        <b>1-3 years of experience</b>
                    </div>
                )}

                {contractor.seniority === 2 && (
                    <div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
                        <img src={midBadge} alt="Mid Badge" style={{ height: 24, marginRight: 12 }} />
                        <b>3-6 years of experience</b>
                    </div>
                )}

                {contractor.seniority === 3 && (
                    <div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
                        <img src={seniorBadge} alt="Senior Badge" style={{ height: 24, marginRight: 12 }} />
                        <b>6-10 years of experience</b>
                    </div>
                )}

                {contractor.seniority === 4 && (
                    <div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
                        <img src={leadBadge} alt="Lead Badge" style={{ height: 24, marginRight: 12 }} />
                        <b>10+ years of experience</b>
                    </div>
                )}

                <div className="bg-purple-light mb-2 rounded-lg p-4 w-full justify-center flex flex-row tooltip">
                    <img src={text_bubble_icon} alt="English Fluency" style={{ width: 24, height: 24, marginRight: 8 }} />
                    <b>Fluent English</b>
                    <span className="tooltiptext" style={{ top: "-50%" }}>
                        English fluency
                    </span>
                </div>
            </div>

            {/* Salary Recommendation and Candidate's Expectation */}
            {application && (
                <div className="flex flex-row mb-3 w-full">
                    {/* Salary Recommendation */}
                    <div className="bg-comuna-blue-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-col text-left">
                        <img src={sliders_icon} alt="Salary Recommendation" style={{ height: 24, width: 24, marginRight: 12 }} />
                        <label className="text-sm">Salary for similar profile in the U.S.</label>
                        <b>${application.salary_recommendation.toLocaleString()} USD</b>
                    </div>

                    {/* What the Candidate Wants */}
                    <div className="bg-comuna-yellow-light mb-2 rounded-lg p-4 w-full flex flex-col text-left">
                        <div className="flex flex-row w-full justify-between">
                            <img src={coins_stacked_icon} alt="Salary Expectation" style={{ height: 24, width: 24, marginRight: 12 }} />
                            {contractor.salary_expectation > application.salary_recommendation && (
                                <div className="pink-badge small text-xs mr-2">Overpriced</div>
                            )}
                        </div>
                        <label className="text-sm">What the candidate wants</label>
                        <b>${contractor.salary_expectation.toLocaleString()} USD</b>
                    </div>
                </div>
            )}

            {/* Offer and Costs */}
            {application?.proposal && (
                <div className="flex flex-row mb-3 w-full">
                    {/* Offer */}
                    <div className="bg-comuna-green-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-col text-left">
                        <img src={coins_hand_icon} alt="Offer" style={{ height: 24, width: 24, marginRight: 12 }} />
                        <label className="text-sm comuna-dark-green-text">Your offer (Salary + Benefits)</label>
                        <b className="comuna-dark-green-text">
                            $
                            {(application.proposal.yearly_salary +
                                GetOneTimeBenefitsCost() +
                                GetMonthlyBenefitsCost())
                                .toLocaleString()
                                .split(".")[0]}
                        </b>
                    </div>

                    {/* Final Cost */}
                    <div className="hover-card bg-purple-light mb-2 rounded-lg p-4 w-full flex flex-col text-left">
                        <div className="flex flex-row w-full justify-between">
                            <img src={receipt_icon} alt="Receipt Icon" style={{ height: 24, width: 24, marginRight: 12 }} />
                            <img src={pie_chart_icon} alt="Pie Chart Icon" style={{ height: 24, width: 24, marginRight: 12 }} />
                        </div>
                        <label className="text-sm comuna-purple-text">Final cost (Salary + Benefits + Taxes + MUNA fee)</label>
                        <b className="comuna-purple-text">
                            ${GetTotalContractCost().toLocaleString().split(".")[0]}
                        </b>
                    </div>
                </div>
            )}

            {/* Skills */}
            <div className="w-full flex flex-col py-2 text-left">
                <b className="text-sm font-semibold">Skills</b>
                <Skills />
            </div>

            {/* Contact Information */}
            <p className="text-sm font-semibold text-left black-text mb-2">Contact Information</p>

            <div className="flex flex-row bg-purple-light justify-between mb-4 p-4 rounded-lg">
                <p className="black-text">Email</p>
                <div className="flex flex-row items-center">
                    <p id="contractor_email" className="font-semibold black-text mr-2">
                        {contractor.email}
                    </p>
                    <button className="focus:opacity-5" onClick={() => copyToClipBoard(contractor.email)}>
                        <img style={{ width: 24, height: 24 }} src={copyIcon} alt="Copy Email" />
                    </button>
                </div>
            </div>

            <div className="flex flex-row bg-purple-light justify-between mb-4 p-4 rounded-lg">
                <p className="black-text">Phone</p>
                <div className="flex flex-row items-center">
                    <p id="contractor_phone" className="font-semibold black-text mr-2">
                        {`${contractor.phone_country_indicator} ${contractor.phone_number}`}
                    </p>
                    <button className="focus:opacity-5" onClick={() => copyToClipBoard(`${contractor.phone_country_indicator} ${contractor.phone_number}`)}>
                        <img style={{ width: 24, height: 24 }} src={copyIcon} alt="Copy Phone" />
                    </button>
                </div>
            </div>

            {/* Notes from MUNA */}
            <div className="flex flex-col mb-3 w-full mt-5 text-left">
                <b className="text-sm font-semibold">Notes from MUNA</b>
                <div className="bg-purple-light mb-2 mt-2 rounded-lg p-4 mr-4 w-full flex flex-col">
                    <p className="text-left black-text">{contractor.note_for_client}</p>
                </div>
            </div>

            {/* Internal Notes */}
            {application && (
                <div className="flex flex-col pb-5 mb-3 w-full mt-5 text-left">
                    <b className="text-sm font-semibold">Internal Notes</b>
                    <div className="bg-purple-light mb-2 mt-2 rounded-lg p-4 mr-4 w-full flex flex-col">
                        <p className="text-left black-text">{application.internal_notes}</p>
                    </div>
                </div>
            )}
        </div>
    );

};

export default ContractorInfo;
